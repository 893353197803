.box-list-super-admin{
    max-width: 1240px !important;
}

.item-hour{
    display: flex;
    flex-direction: column !important;
    gap: 5px;
    .title-day{
        height: 30px;
    }
    .menu-left{
        height: 32px;
        display: flex;
        justify-content: center;
        margin: 0px;
        align-items: center;
    }
}

.bloc-image{
    width: 300px;
    height: 300px;
    display: flex;
    justify-content: center;
    background: #f7f7f8;
    border-radius: 10px;
    margin-top: 20px;
    label{
        width: 100%;
        &:hover, &:focus{
            background: none;
        }
    }
    svg{
        font-size: 9rem;
        fill: #FFFFFF;
    }
}

.box-rdv-dashboard {
    max-width: 1200px;
    margin: auto;
    padding-bottom: 10px;
    border-radius: 10px;
    min-height: 50vh;
    margin-top: 30px;
    margin-bottom: 20px;
}

.box-mairie{
    max-width: 1200px;
    margin: auto;
    position: relative;
    background: #FFFFFF;
    padding: 1rem;
    border-radius: 10px;
    min-height: 50vh;
    margin-top: 30px;
    margin-bottom: 20px;
    .MuiSelect-select {
        background: #f7f7f8 !important;
    }
    
    .tab-mairie{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .step-mairie{
            cursor: pointer;
            &:hover, &.active{
                font-weight: 500;
                text-decoration: underline;
            }
        }
    }
}

.textarea-mairie{
    border: 1px solid rgba(0, 0, 0, 0.23);
}

.div-btn-next{
    display: flex;
    align-items: flex-end;
    .btn-next{
        margin-top: 20px !important;
    }
}

.bloc-info-mairie{
    .name-mairie{
        font-size: 18px;
    }    
}

.item-calendar{
    width: 70px !important;
    height: 25px;
    padding: 0px;
    align-items: center;
    margin: 5px 0px;
}

.bloc-hour{
    background: #f7f7f8;
    padding: 1rem;
    border-radius: 8px;
    .title-bloc{
        color: #727272;
    }
}

.grid-mairie{
    margin: auto;
    width: auto !important;
    margin-top: 0px !important;
    margin-left: 0px !important;
    table{
        th{
            font-weight: 900;
        }
    }
}

.btn-delete{
    color: red;
    cursor: pointer;
}

.text-red{
    color: red;
}

.details-mairie{
    width: 964px !important;
}

.mini-title{
    padding: 0.5rem 1rem;
    background: #f2f2f2;
    text-transform: uppercase;
    font-weight: 500;
}

.image-mairie{
    width: 250px;
    height: 250px;
    object-fit: cover;
    border-radius: 10px;
}

.div-btn-save{
    margin: 10px 0px;
    gap: 10px;
    justify-content: flex-end;
    display: flex;
}

.bloc-number-mairie{
    background: #F2F2F2;
    p{
        text-transform: uppercase;
    }
    input{
        background: white;
    }   
}

.profile-mairie{
    max-width: 100%;
    height: 100%;
    object-fit: contain;
}

.grid-parameter{
    max-width: 750px;
    margin: auto;
    margin-top: 2rem;
}

.token-text{
    overflow: hidden;
    white-space: nowrap;
    width: 300px;
    text-overflow: ellipsis;
}

.item-holiday{
    background: white;
    border-radius: 10px;
    padding: 0px 10px;
}