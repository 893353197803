.big-text{
    font-size: 2rem;
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
    @media (max-width: 640px) {
        font-size: 1.5rem;
    }
}

.big-title-red{
    font-weight: 700;
    font-size: 2rem;
    color: #C74C51;
    margin: 0;
    @media (max-width: 640px) {
        font-size: 1.5rem;
    }
}

.bloc-list-rdv{
    margin-top: 30px;
    background: #F9F9F9;
    padding: 1.5rem 2rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    @media (max-width: 1024px) {
        padding: 1rem 0.5rem;
    }
    .logo-mairie{
        width: 50%;
        height: auto;
    }
    .name-mairie{
        font-size: 1rem;
        font-weight: 600;
        color: #C74C51;
        margin: 0;
    }
    .type-motif{
        margin: 0;
        font-size: 1rem;
    }
    .duration{
        color: #7B7B7B;
        font-size: 1rem;
    }

    .status-actif{
        text-align: right;
        position: relative;
        font-size: 1.2rem;
        font-weight: 600;
        width: 65px;
        &::after{
            content: '';
            position: absolute;
            width: 15px;
            height: 15px;
            left: 0;
            top: 25%;
            border-radius: 50%;
            background: #00FF33;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        }
    }

    .status-cancel{
        text-align: right;
        position: relative;
        font-size: 1.2rem;
        font-weight: 600;
        &::after{
            content: '';
            position: absolute;
            width: 15px;
            height: 15px;
            left: -20px;
            top: 25%;
            border-radius: 50%;
            background: red;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        }
    }
}

.date{
    color: #FFFFFF;
    background: #23587F;
    padding: 0.5rem;
    font-size: 1rem;
    font-weight: 800;
    text-align: center;
    margin-top: 15px;
    width: 80%;
    @media (max-width: 1024px) {
        width: 100%;
    }
}

.btn-rdv{
    padding: 0rem 2rem;
    height: 40px;
}

.btn-icon{
    svg{
        font-size: 30px !important;
    }
}

.success-bloc{
    svg{
        fill: green;
        font-size: 4rem;
    }
}

.text-empty{
    font-size: 24px;
    text-align: center;
    margin-top: 25px;
    font-weight: 500;
}

.info-usager{
    background: #f6f6f6;
    padding: 1rem;
    position: relative;
}

.edit-usager{
    position: absolute;
    right: 15px;
    top: 25px;
    cursor: pointer;
}