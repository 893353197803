.rotate-inverse{
    position: absolute;
    right: 5px;
    transform: rotateY(180deg);
    cursor: pointer;
}

.rotate-normale{
    position: absolute;
    right: 5px;
    transform: rotateY(0deg);
    cursor: pointer;
}