.menu-mairie{
    display: flex;
    max-width: 30%;
    height: 30px;
    background: #B1B1B1;
    justify-content: space-between;
    margin: auto;
    gap: 10px;
    padding-right: 5px;
    padding-left: 5px;
    border-radius: 8px;
    .item-sous-menu{
        display: flex;
        justify-content: center;
        width: 100%;
        font-size: 13px;
        align-items: center;
        height: 25px;
        margin: auto;
        font-weight: 500;
        border-radius: 5px;
        cursor: pointer;
        &:hover, &.active{
            background: #FFFFFF;
        }
    }
}

.box-list-super-admin{
    max-width: 1240px !important;
}

.box-mairie{
    max-width: 1024px;
    margin: auto;
    background: #FFFFFF;
    padding: 1rem;
    border-radius: 10px;
    min-height: 50vh;
    margin-top: 30px;
    margin-bottom: 20px;
    .MuiSelect-select {
        background: #f7f7f8 !important;
    }
    .bloc-image{
        width: 300px;
        height: 300px;
        display: flex;
        justify-content: center;
        background: #f7f7f8;
        border-radius: 10px;
        margin-top: 20px;
        label{
            width: 100%;
            &:hover, &:focus{
                background: none;
            }
        }
        svg{
            font-size: 9rem;
            fill: #FFFFFF;
        }
    }
    .tab-mairie{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .step-mairie{
            cursor: pointer;
            &:hover, &.active{
                font-weight: 500;
                text-decoration: underline;
            }
        }
    }
}

.div-btn-next{
    display: flex;
    align-items: flex-end;
    .btn-next{
        margin-top: 20px !important;
    }
}

.bloc-info-mairie{
    .name-mairie{
        font-size: 18px;
    }    
}

.grid-mairie{
    margin: auto;
    width: auto !important;
    margin-top: 0px !important;
    margin-left: 0px !important;
    table{
        th{
            font-weight: 900;
        }
    }
}

.btn-delete{
    color: red;
    cursor: pointer;
}

.details-mairie{
    width: 964px !important;
}

.image-mairie{
    width: 250px;
    height: 250px;
    object-fit: cover;
    border-radius: 10px;
}

.div-btn-save{
    margin: 10px 0px;
    gap: 10px;
    justify-content: flex-end;
    display: flex;
}

.bloc-number-mairie{
    background: #F2F2F2;
    p{
        text-transform: uppercase;
    }
    input{
        background: white;
    }   
}

.text-historique{
    background: #E1E1E1;
    text-align: center;
}

.custom-hr{
    height: 5px;
    background: #bfbfbf;
}

.text-lastname{
    font-size: 30px;
    font-weight: 600;
}

.text-firstname{
    font-size: 30px;
    font-weight: 100;
}

.bloc-export{
    max-width: 500px;
    width: 100%;
    background: #D2C9C2;
    border-radius: 10px;
    padding: 2rem;
    margin: auto;
}

.bloc-export-full{
    max-width: 100%;
    margin: initial;
}

.title-export{
    font-size: 20px;
    color: #176FBD;
    font-weight: 700;
    margin-bottom: 0px;
}

.details-export{
    margin-top: 1rem;
}

.type-file{
    background: #2B2B2B;
    color: white;
    padding: 5px;
    width: 50px;
    border-radius: 6px;
    text-align: center;
    margin: 10px 0px;
    cursor: pointer;
}