.box-cgu{
    display: flex;
    min-height: 100vh;
    justify-content: center;
    align-items: center; 
    background: url("/application/assets/images/bg-login.png") no-repeat;
    background-size: cover;
    background-attachment: fixed;
    h1{
        font-size: 1.6rem;
        text-align: center;
        color: #C74C51;
        font-weight: 700;
    }
    
    .div-cgu{
        max-width: 700px;
        min-height: 700px;
        margin: 2rem;
        .title{
            font-weight: bold;
            text-transform: uppercase;
            color: #C74C51;
            font-size: 1.3rem;
        }

        .mini-title{
            font-weight: bold;
            color: #C74C51;
            margin: 10px 0px;
        }

        ul{
            list-style: inside;
        }
    }
}
