.active-route {
  background-color: rgba(38, 49, 81, 0.05) !important;
  border-radius: 10px !important;
  * {
    color: #ff6464 !important;
    font-weight: bold !important;
  }
}

.dialog-info{
  .MuiDialog-paper {
    width: auto;
    @media (max-width: 464px) {
      margin: 0px;
    }
  }
  .MuiDialog-container{
    z-index: 1;
  }
}

.MuiSnackbar-anchorOriginTopRight{
  margin-top: 70px
}