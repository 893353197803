.container-collectivity{
    background: white;
    max-width: 85%;
    .container-grid{
        padding: 3rem;
        min-height: 80vh;
        padding: 3rem;
        min-height: 80vh;
        padding-top: 150px;
        background: #fafafa;
        @media (max-width: 640px) {
            padding-top: 100px;
        }
        .title{
            text-transform: uppercase;
            font-size: 2.5rem;
            font-weight: 200;
            text-align: center;
            span{
                font-weight: 500;
            }
            @media (max-width: 640px) {
                font-size: 1.8rem;
            }
        }
        .mini-title-collectivity{
            text-transform: uppercase;
            font-size: 1.5rem;
            font-weight: 200;
            text-align: center;
        }

        .text-bold{
            font-weight: 800;
        }

        .tarif{
            width: fit-content;
            border-radius: 15px;
            padding: 0 20px;
            background: #c74d51;
            font-size: 24px;
            color: white;
        }

        .title-red{
            font-size: 2rem;
            font-weight: 200;
            text-align: center;
            color: #c74d51;
            span{
                font-weight: 800;
                text-transform: uppercase;
            }
        }

        .info-bloc-title{
            padding: 5px 50px;
            background: #c74d51;
            color: white;
            font-size: 1.3rem;
            border-radius: 20px;
            @media (max-width: 640px) {
                font-size: inherit;
                padding: 5px 10px;
            }
        }

        .list-info{
            list-style: disc;
            font-size: 16px;

            .list{
                list-style: inside;
            }
            li{
                margin-top: 10px;
            }
        }

        .list-price{
            list-style: inside;
        }

        .list-info-ants{
            font-size: 16px;
            list-style: number;
            li{
                margin-top: 10px;
            }
        }

        .text-ants{
            font-size: 16px;
            max-width: 260px;
            font-weight: 600;
        }
        
    }

    
}
.footer-landing{
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 50px 0px;
    
}

.info-footer{
    font-weight: 700;
    font-size: 1.5rem;
}