@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: rgb(249, 250, 251) !important;
  p{
    margin-bottom: 0px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.div-content{
  min-height: 100vh;
  background: #F7F7F8;
  padding-top: 115px;
}

.ant-picker-dropdown{
  z-index: 100000 !important;
}

.SnackbarItem-wrappedRoot{
  max-width: 400px;
}

.SnackbarContainer-top{
  top: 100px;
}

.ql-editor{
  height: 200px !important;
}

.sticky-element{
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  top: 0;
}

.MuiTableSortLabel-root {
  color: white !important;
}

.MuiTableSortLabel-icon {
  color: white !important;
}

.name-user{
  color: #000;
  font-weight: 500;
}

.box-loading{
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 80px);
}

.fs-18{
  font-size: 18px;
}

.fw-600{
  font-weight: 600;
}