.card-concerned{
    padding: 10px;
    background: gainsboro;
    margin: 0px -7px;
    border-radius: 5px;
    margin-top: 10px;
    .btn-delete{
        position: absolute;
        right: 25px;
    }
}