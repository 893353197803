.box-admin{
    .MuiSelect-select {
        background: #f7f7f8 !important;
    }
    .form-parameter{
        margin: auto;
        max-width: 800px;
        .form-radio{
            flex-direction: row;
            display: flex;
            margin-top: 10px;
            gap: 20px;
            .title-radio{
                min-width: 140px;
                margin-top: 10px;
            }
            .group-radio{
                width: 400px;
                justify-content: flex-start;
            }
            label{
                color: #000000;
                font-weight: 600;
                min-width: 100px;
            }
        }
    }
}

.error-limit-agenda{
    text-align: center;
    color: red;
    font-weight: 600;
    font-size: 16px;
}

.bloc-hour{
    background: #F7F7F8;
    padding: 1rem;
    border-radius: 8px;
    .title-bloc{
        color: #727272;
    }
}

.text-disabled-hour{
    margin-top: -5px;
    border-radius: 0px 0px 5px 5px;
    background: #DAADAF;
    display: flex;
    justify-content: flex-end;
    padding: 5px 20px;
}