
.box-info-mairie{
    width: 100vh;
    padding: 0 2rem 2rem 2rem;
    @media (max-width: 764px) {
        width: auto;
    }
    .title-mairie{
        font-size: 1.6rem;
        font-weight: 600;
        color: #23587F;
    }
    .text-hour{
        color: #23587F;
        font-weight: 600;
        font-size: 1.2rem;
    }

    .box-info-adress{
        padding: 1rem;
        background: #F2F2F2;
        margin: 20px 0px;
        .title-adress{
            color: #23587F;
            font-weight: 700;
        }
    }
}
