.box-login{
    display: flex;
    min-height: 100vh;
    justify-content: center;
    align-items: center; 
    background: url("/application/assets/images/bg-login.png") no-repeat;
    background-size: cover;
    background-attachment: fixed;
    .div-warning{
        min-width: 400px;
        padding: 2rem;
        margin: auto;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        background: #FFFFFF;
        border-radius: 10px;
    }
}
