.header-1-container {
  position: relative;
  width: 100%;
  background: url("/application/assets/images/bg-1.png") no-repeat;
  background-size: cover;
  background-attachment: fixed;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.header-2-container {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  gap: 3em;
  position: relative;
  
  h2 {
    margin-top: .5em;
  }
  
  #background2 {
    position: absolute;
    bottom: -110px;
    z-index: -1;
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
}

@media screen and (max-width: 768px) {
  .header-1-container {
    padding-top: 30px;
  }
  .header-2-container {
    margin-top: 0;
  }
}

.box-logo{
  svg{
    width: 80%;
    height: 100%;
    @media (max-width: 568px) {
      width: 100%;
    }
  }
}

.box-description{
  width: 70%;
  font-size: 1.3rem;
  margin-top: 4rem;
  color: #FFFFFF;
  @media (max-width: 568px) {
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}