.box-ants{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 50%;
    border-radius: 10px;
    background: white;
    padding: 20px;
    @media (max-width: 768px) {
        min-width: 80%;
    }
}
.box-btn{
    display: flex; 
    justify-content: space-between;
    gap : 5px; 
    margin: 10px auto; 
    
    @media (max-width: 768px) {
        flex-wrap: wrap;
        button{
            max-width: 100%;
        }
    }
}