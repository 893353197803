.tab-menu{
    display: flex;
    max-width: 70%;
    height: 30px;
    background: #B1B1B1;
    justify-content: space-between;
    text-transform: uppercase;
    margin: auto;
    gap: 10px;
    padding-right: 5px;
    padding-left: 5px;
    border-radius: 8px;
    .item-sous-menu{
        display: flex;
        justify-content: center;
        width: 100%;
        font-size: 13px;
        align-items: center;
        height: 25px;
        margin: auto;
        font-weight: 500;
        border-radius: 5px;
        cursor: pointer;
        &:hover, &.active{
            background: #FFFFFF;
        }
    }
}
