.switch-label-motif{
    width: max-content;
    padding: 5px 10px;
    min-width: 20%;
    border-radius: 10px;
    display: flex;
    margin: 0 !important;
    justify-content: space-between;
    .MuiSwitch-track:before {
        top: 38% !important;
    }
}


.info{
    margin-top: 5px;
    background: #DAADAF;
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 5px 20px;
}

.div-checkBox{
    display: flex;
    align-items: center;
    gap: 20px;
}

.info-content{
    background: #E5E5E5;
    padding: 3px 10px;
    border-radius: 5px;
    width: 50%;
}

.radius-top{
    border-radius: 10px 10px 0px 0px;
}

.box-grey{
    max-width: 1024px;
    margin: auto;
    border-radius: 10px;
    margin-top: 30px;
    margin-bottom: 20px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 5px;
}

.box-modal{
    max-width: 1024px;
    margin: auto;
    padding: 0px 2rem;
    margin-bottom: 20px;
}