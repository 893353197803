.box-login{
    display: flex;
    min-height: 100vh;
    justify-content: center;
    align-items: center; 
    background: url("/application/assets/images/bg-login.png") no-repeat;
    background-size: cover;
    background-attachment: fixed;
    .div-login{
        min-width: 400px;
        min-height: 300px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        background: #FFFFFF;
        .logo{
            margin: 20px auto;
        }
        button{
            width: 100%;
            height: 40px;
            margin-top: 10px;
        }
    }
}
