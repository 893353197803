.box-container{
    max-width: 1200px;
    position: relative;
    padding: 1rem;
    min-height: 50vh;
    margin: 30px auto 20px;
}

.volume-sms-container {
    table {
        border: 1px solid black;
        margin: 2em auto;
        thead {
            th {
                background-color: #d2c9c1;
                padding: .25em;
                border: 1px solid black;
            }
        }
        tbody {
            tr:nth-of-type(odd) { background-color: #e4e4e4 };
            tr:nth-of-type(even) { background-color: white };
            td {
                border-left: 1px solid black;
                padding-left: 1em
            }
        }
    }
}
