button {
  text-transform: none;
}

.card-cni {
  height: 200px;
  position: relative;
  p {
    position: absolute;
    bottom: 40px;
    width: 100%;
    padding: .35em;
    background-color: rgba(243, 243, 243, 0.56);
  }
  img {
    width: 100%;
    height: 185px;
    object-fit: cover;
    filter: blur(2px);
    border: 1px solid #9ca3af;
    border-radius: 4px;
  }
}

.confirmed-poster {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 3em;
  padding-bottom: 3em;
  .logo-rdv {
    position: absolute;
    top: 80px;
    width: 300px;
  }
}

.calendar-custom {
  margin: auto;
  .navigation-button-calendar-weeks {
    position: absolute;
    top: 80px;
    z-index: 2;
    width: 105%;
    justify-content: space-between;
  
    button:nth-child(1) {
      left: -15px
    }
    button:nth-child(2) {
      right: -15px
    }
  }
  
  .title-calendar{
    background: #c84c51;
    color: white;
    padding: 10px;
  }

  .column-hour{
    min-width: 80px;
    height: 150vh;
  }
}

.fw-500{
  font-weight: 500;
}

.bg-grey{
  background: #f7f7f8 !important;
}

.bg-min-grey{
  background: #F2F2F2 !important;
}

.bg-dark-grey{
  background: #D2D2D2;
}

.text-light-grey{
  color: #727272;
}

.padding-left-right{
  padding: 0 1rem;
}

.border-radius-left{
  border-radius: 5px 0px 0px 5px !important;
}

.border-radius-right{
  .MuiInputBase-root{
    border-radius: 0px 5px 5px 0px !important;
  }
}

.small-text{
  font-size: 13px;
}

.w-auto-important{
  width: auto !important;
}

.div-icon-logout{
  display: flex;
  justify-content: flex-end;
  padding: 0px 10px;
  .icon-logout{
    fill: white;
    background: #c84c51;
    border-radius: 50%;
    padding: 0.3rem;
    font-size: 30px !important;
    cursor: pointer;
  }
}

textarea{
  padding: 13px;
  border-radius: 5px;
  &:focus{
    border-color: #c84c51;
  }
  &:focus-visible{
    border-color: #c84c51;
    outline: #c84c51 auto 1px;
  }
}

.btn-save-reset{
  display: flex;
  gap: 10px;
  width: 100%;
  margin: 10px auto;
  justify-content: end;
}

.bold-title{
  font-weight: 600;
  text-transform: uppercase;
}

.title-bold{
  font-size: 1.2rem;
  font-weight: 600;
}

.border-left-red{
  border-left: 5px solid #c84c51
}

.warning-bloc{
  background-color: rgba(235, 110, 125, 0.56);
}

.bold{
  font-weight: 600 !important;
}

.blue-label{
  color: #176FBD;
  font-weight: 600;
}

.radio-left-right{
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
}

.switch-label{
  background: #EEEEEE;
  width: max-content;
  padding: 5px 10px;
  min-width: 30%;
  border-radius: 10px;
  display: flex;
  margin: 0 !important;
  justify-content: space-between;
  .MuiSwitch-track:before {
    top: 35%;
  }
}

.input-time-picker {
  padding: 4px 8px;
  input{
    text-align: center;
  }
  .ant-picker-suffix{
    display: none;
  }
}

legend{
  width: auto !important;
}

.text-vertical{
  display: flex;
  flex-direction: column;
  .MuiFormControlLabel-label{
    transform: rotate(180deg);
    writing-mode: vertical-rl;
  }
}

.profile-mairie-preview{
  max-width: 200px;
  height: 100%;
  object-fit: contain;
}

.box-desk{
  max-width: 1024px;
}

.disableDiv{
  pointer-events: none;
}

.MuiPopover-paper {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 5px !important;
}

.header-alert{
  background: #c84c51;
  color: #fff;
  padding: 10px;
}

.bloc-text-alert{
  padding: 1rem;
  max-height: 500px;
  overflow: auto;
  .text-alert{
    text-align: center;
    margin-bottom: 15px;
  }
}

.box-admin{
  max-width: 1100px;
  margin: auto;
  background: #FFFFFF;
  padding: 1rem;
  border-radius: 10px;
  margin-top: 30px;
  margin-bottom: 20px;
  .MuiSelect-select {
      background: #f7f7f8 !important;
  }
}

.pl-0{
  padding-left: 0px !important;
}

.filepond--credits{
  display: none;
}