.Overlay-halfSize {
    height: 70% !important;
    width: 100% !important;
    bottom: 0;
    top: auto !important;
    background-color: rgba(255, 255, 255, 1) !important;
    &::before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        filter: blur(10px);
        z-index: -1;
    }
}

.text-warning {
    font-size: 20px;
    text-align: center;
    padding: 0.5rem;
    font-weight: 500;
}

.box-content-appointment {
    max-width: 80%;
    margin: auto;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    .title {
        margin: 0 !important;
        font-weight: 700;
        font-size: 1rem;
        text-transform: uppercase;
    }
}

.custom-overlay {
    position: fixed !important;
}

.MuiSelect-select {
    background: #ffffff !important;
}

.VerticalAppointment-content {
    color: #000000 !important;
}

.item-calendar {
    width: 70px !important;
    height: 25px;
    padding: 0px;
    align-items: center;
    margin: 5px 0px;
}

.Layout-stickyContainer {
    display: none !important;
}
.Layout-root {
    width: 100%;
    padding: 0px 2rem;
}

.bloc-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f6f6f6;
    padding: 0px 15px;
    margin-bottom: 30px;
    border-radius: 5px;
    .MuiFormControl-root {
        margin: 10px 0px;
    }
}

.text-blue {
    cursor: pointer;
    font-weight: 700;
    color: #235980;
}

.bloc-details {
    .name {
        font-size: 25px;
        font-weight: 200;
    }
    .lastname {
        font-weight: 500;
    }
    .phone {
        margin-bottom: 10px;
    }
    .text-title {
        font-size: 14px;
        margin-top: 10px;
    }
    .text-date {
        text-transform: capitalize;
    }
    .text-value {
        font-weight: 500;
    }
}

.Content-dateAndTitle {
    text-transform: capitalize;
}

.bloc-input-textarea {
    background: #f6f6f6;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 5px;
    label {
        margin-bottom: 0.5rem;
    }
    textarea {
        resize: none;
        width: 100%;
        padding: 5px;
    }
}

.btn-bottom {
    display: flex;
    justify-content: flex-end;
}

.table-historique {
    background: #f3f3f3;
    border-radius: 5px;
    .title-historique {
        font-size: 18px;
        padding: 10px;
        text-transform: uppercase;
    }
}

.status-icon {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 2px;
    right: 3px;
    gap: 1px;
    svg {
        width: 18px;
        height: 18px;
        background: white;
        border-radius: 50%;
    }
    .text-green {
        fill: #00ba00;
        font-size: 16px;
    }
}

.info-usager {
    background: #f6f6f6;
    padding: 1rem;
    position: relative;
}

.edit-usager {
    position: absolute;
    right: 15px;
    top: 25px;
    cursor: pointer;
}

.duplicate {
    position: absolute;
    top: 2px;
    right: 3px;
}

.BO-text {
    color: #000000;
}

div:has(div.BO-notCanceledAppointment) {
    z-index: 1;
}

.bloc-scheduler {
    .Container-container {
        max-height: calc(100vh - 150px) !important;
    }
    .MuiTableCell-root {
        div {
            line-break: anywhere;
        }
    }
}
