.div-sous-menu{
    display: flex;
    max-width: 70%;
    // width: max-content;
    height: 50px;
    background: #3D3D3D;
    justify-content: space-between;
    text-transform: uppercase;
    margin: auto;
    gap: 10px;
    padding-right: 10px;
    padding-left: 10px;
    border-radius: 8px;
    @media (max-width: 1050px) {
        max-width: 95%;
    }
    .item-sous-menu{
        display: flex;
        justify-content: center;
        width: 90%;
        min-width: max-content;
        align-items: center;
        height: 35px;
        margin: auto;
        padding: 18px;
        border-radius: 5px;
        color: #FFFFFF;
        cursor: pointer;
        &:hover{
            background: #AAAAAA;
        }
        &.active{
            background: #AAAAAA;
        }

    }
}